@tailwind base;
@tailwind components;
@tailwind utilities;

/* for testing environment only - staging */
.css-zw3mfo-MuiModal-root-MuiDialog-root {
  margin-top: 1.75rem;
}

:root {
  --primarycolor: #457b9d;
  --color2: #f1faee;
  --color3: #e63946;
  --color4: #a8dadc;
  --color5: #457b9d;
}
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: var(--color2);
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

@layer components {
  .primaryButton {
    @apply px-6 py-2 font-semibold rounded bg-[color:var(--color5)] text-[color:var(--color2)];
  }

  .btn4 {
    @apply px-4 py-2 rounded font-semibold bg-[color:var(--color3)] text-[color:var(--color2)];
  }

  .container_xxl {
    @apply max-w-[1536px] mx-0 px-8;
  }

  .row {
    @apply flex flex-wrap;
  }
  .titleHeading {
    @apply text-4xl font-semibold py-5 text-gray-700 text-center;
  }
  .heading {
    @apply text-4xl font-semibold py-5 text-gray-700;
  }
  .headingBorder__b {
    @apply border-b border-gray-600;
  }
  .label {
    @apply mb-2 text-base font-semibold inline-block text-gray-700 capitalize;
  }

  .input {
    @apply w-full border focus:outline-none focus-within:shadow px-3 py-2 rounded placeholder:italic placeholder:text-sm;
  }

  .text_area {
    @apply border min-h-[6rem] rounded px-3 py-2 focus:outline-none focus-within:shadow placeholder:italic placeholder:text-sm w-full;
  }
  .editStudent-tab {
    @apply py-2 px-5 capitalize rounded-md cursor-pointer font-semibold bg-blue-200 text-xl;
  }

  .mandatory {
    @apply text-red-800;
  }

  .center-the-div {
    @apply absolute top-12 left-1/2 -translate-x-1/2;
  }

  .inprogress_report {
    @apply bg-yellow-200 text-yellow-700;
  }
  .notgenerated_report {
    @apply bg-red-200 text-red-700;
  }
  .generated_report {
    @apply bg-green-200 text-green-700;
  }
}

.errorMessage {
  display: none;
}
.input:invalid[focused="true"] {
  border: 1px solid #b91c1b;
}
.input:invalid[focused="true"] ~ small {
  display: block;
}

.editStudent-tab.active {
  background-color: var(--primarycolor);
  color: #fff;
}

.tab-content {
  display: none;
}
.tab-content.active {
  display: block;
}

.react-datepicker__input-container > input {
  width: 100%;
  border-radius: 0.25rem;
  border-width: 1px;
  padding-left: 0.75rem;
  padding-right: 0.75rem;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
}

.css-13cymwt-control,
.css-t3ipsp-control {
  width: 100%;
  border-radius: 0.25rem;
  border-width: 1px;
  padding-top: 0.08rem;
  padding-bottom: 0.08rem;
  border-color: #e5e7eb;
}

.css-13cymwt-control:focus,
.css-13cymwt-control:focus {
  outline: none;
}

.react-datepicker__input-container > input:focus {
  outline: none;
}

input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.sub-subject::before {
  content: "";
  position: absolute;
  width: 1.7rem;
  height: 1px;
  background-color: lightgray;
  left: -28px;
  top: 50%;
  transform: translateY(-50%);
}
.sub-subject::after {
  content: "";
  position: absolute;
  width: 1px;
  height: 3.5rem;
  background-color: lightgray;
  left: -28px;
  top: -35px;
}


.select-search-input{
  background: white !important;
  color: black !important;
  font-size: 13px !important;
}

.select-search-option{
  font-size: 13px !important;
  height: auto !important;
  border-bottom: 1px solid white !important;
  padding: 5px !important;
}

.select-search-select{
  position: static !important;
}

.hpanel{
  margin: 0;
  padding: 0;
  background: linear-gradient(to bottom right, #D7BBEA, #65A8F1);
  height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}